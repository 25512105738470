import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Login from './components/Login/Login';
import Dashboard from './components/Dashboard/Dashboard';
import PaymentPlanUpgrade from './components/Payments/PaymentUpgradeMain';
import PaymentPlanRenew from './components/Payments/PaymentRenewalMain';
import Success from './components/Payments/Success';
import { BrowserRouter as Router, Routes, Route, Navigate, useNavigate } from 'react-router-dom';

const root = ReactDOM.createRoot(document.getElementById('root'));

// Function to validate token
const checkToken = async () => {
  const token = localStorage.getItem('token');
  const spUnique = localStorage.getItem('sp_unique');

  if (token && spUnique) {
    
    try {
      const response = await fetch('https://access.myservicecircle.app/wcapi/1_entryPoint.php', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ token, sp_unique:spUnique }),
      });

      const data = await response.json();
      //alert(JSON.stringify(data));
      if (data.success == 1) {
        return { valid: true, token: data.token };
      }
    } catch (error) {
      console.error('Error validating token:', error);
    }
  }

  localStorage.clear();
  return { valid: false };
};

// Main Component
const Main = () => {
  const [loading, setLoading] = useState(true);
  const [authenticated, setAuthenticated] = useState(false);
  const [token, setToken] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const initialize = async () => {
      const { valid, token } = await checkToken();
      setAuthenticated(valid);
      setToken(token);
      setLoading(false);
      if (!valid) navigate('/Login');
    };
    initialize();
  }, [navigate]);

  if (loading) return <div>Loading...</div>;

  return (
    <Routes>
      <Route path="/Login" element={authenticated ? <Navigate to={`/Dashboard/${token}`} /> : <Login />}/>
      <Route path="/Dashboard/:token" element={authenticated ? <Dashboard /> : <Navigate to="/Login" />}/>
      <Route path="*" element={<Navigate to={authenticated ? `/Dashboard/${token}` : '/Login'} />} />
      <Route path="/PaymentPlanUpgrade/:planId/:planName/:days/:discount" element={<PaymentPlanUpgrade />} />
      <Route path="/PaymentPlanRenew/:planId/:days/" element={<PaymentPlanRenew />} />
      <Route path="/Success/:txnId" element={<Success />} />

    </Routes>
  );
};

root.render(
  <Router>
    <Main />
  </Router>
);
