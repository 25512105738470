import React, {useState, useEffect, CSSProperties } from 'react';
import logoLong from '../../assets/images/logo_full_length.png';
import logoSmall from '../../assets/images/logo.png';
import upscaledImage from '../../assets/images/upscaled.png';
import BottomNavigation from '../Common/BottomNavigation';
import { Swiper, SwiperSlide } from 'swiper/react';
import './PaymentUpgradeMain.css';
import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Autoplay } from 'swiper/modules';
import { motion } from "framer-motion";
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom'
import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import { 
  FaHome, FaUserAlt, FaCog, FaClipboard, FaChartBar, FaTools, FaRegFileAlt, 
  FaUsers, FaUserTie, FaComments, FaBell, FaShoppingCart, 
  FaFontAwesome
} from 'react-icons/fa';
import { AiOutlineArrowRight, AiOutlineArrowLeft } from "react-icons/ai";
import { FiPackage } from "react-icons/fi";
import HeaderBar from '../Common/HeaderBar';
import SideBar from '../Common/SideBar';

import '../Dashboard/Dashboard.css';

import { BounceLoader, CircleLoader, ClipLoader, BeatLoader, BarLoader, FadeLoader } from 'react-spinners';
import { EditablePropsProvider } from '@chakra-ui/react';
import { Pane, Heading, Text, Card, Button, Dialog, TextInput} from 'evergreen-ui';
import { toaster } from 'evergreen-ui';
import { MdOutlineChat, MdCall, MdOutlineVideoCameraFront, MdOutlineCheck, MdOutlineCancel, MdOutlineAddShoppingCart, MdBorderRight, MdCheck, MdClose} from "react-icons/md";
import { LuBicepsFlexed } from 'react-icons/lu';

const PaymentPlanRenew = () =>{

    const [taskLoader, setTaskLoader] = useState(false);

    const {planId, days} = useParams();
    const navigate = useNavigate();
    const [couponBox, setCouponBox] = useState(false);
    const [couponCode, setCouponCode] = useState(null);
    const [couponApplied, setCouponApplied] = useState(false);
    const [couponDiscount, setCouponDiscount] = useState(null);
    const [allPlans, setAllPlans] = useState(null);
    const [currentPlan, setCurrentPlan] = useState(null);
    const [selfProfile, setSelfProfile] = useState(null);

    const [finalMainPrice, setFinalMainPrice] = useState(null);

    useEffect(() => {
      // Function to handle back button detection
      const handleBackButton = () => {
        console.log('You pressed the Back button!');
        // Perform navigation or other actions here
        navigate('/Login'); // Example: Redirect to the dashboard
      };
  
      // Add the event listener for popstate
      window.addEventListener('popstate', handleBackButton);
  
      return () => {
        // Clean up the event listener on component unmount
        window.removeEventListener('popstate', handleBackButton);
      };
    }, [navigate]);

    useEffect(()=>{
      if(allPlans){
        const plan = allPlans.filter(plan=> plan.plan_id === planId);        
        setCurrentPlan(plan[0]);
      }else{
        fetchAllPlans();
        fetchSelfProfile();
      }
    },[allPlans]);


    const fetchAllPlans = async () =>{
      const sp_unique = localStorage.getItem('sp_unique');
      setTaskLoader(true);
      try{
        const response = await fetch('https://access.myservicecircle.app/sp/7b_fetchPlansAndController.php',{
          method:'POST',
          headers:{
            'Content-Type' : 'application/json'
          },
          body:JSON.stringify({sp_unique})
        });

        const responseData = await response.json();

        setAllPlans(responseData.plans);
      }catch(error){
        console.log(error);
      }finally{
        setTaskLoader(false);
      }
    }

    const fetchSelfProfile = async() =>{
      const sp_unique = localStorage.getItem('sp_unique');
      try{
        const response = await fetch('https://access.myservicecircle.app/sp/1_selfData.php',{
          method:'POST',
          headers:{
            'Content-Type':'application/json'
          },
          body:JSON.stringify({sp_unique}),
        });

        const responseData = await response.json();

        if(responseData.success == 1){  
          setSelfProfile(responseData.details);
        }else{
          toaster.warning('Something went wrong...', 2000);
        }
      }catch(error){  
        console.log(error);
      }
    }

        const handleCouponCode = (event) =>{        
            setCouponCode(event.target.value);
        }

      const verifyCouponCode = async ()=>{
              if(!couponCode){
                toaster.warning("Please type a coupon code to proceed", 2000);
                return;
              }
              const sp_unique = localStorage.getItem('sp_unique');
              const type = 1;
              const coupon = couponCode;
              const plan_id = planId;
              setTaskLoader(true);
              try{
                const dataString = {
                  sp_unique,
                  type,
                  coupon,
                  plan_id
                }
                console.log(dataString);
                const response = await fetch('https://access.myservicecircle.app/payment_gateway/verifyCouponCode.php',{
                  method:'POST',
                  headers:{
                    'Content-Type':'application/json'
                  },
                  body:JSON.stringify(dataString)
                });
      
      
                const responseData = await response.json();
                console.log(responseData);
      
                if(responseData.success == 1){
                  setCouponApplied(true);
                  setCouponDiscount(responseData.discount);
                }else{
                  toaster.danger(`Coupon code is invalid`, 2000);
                }
              }catch(error){
      
              }finally{
                setTaskLoader(false);
              }
            }

            const BasePriceSlab = () =>{
              let symbol, currency, priceKey, basicPrice;
              if(selfProfile && currentPlan){
                  if (selfProfile.country === 'India') {
                    symbol = '₹';
                    currency = 'inr';
                  } else if (selfProfile.country === 'United States') {
                    symbol = '$';
                    currency = 'usd';
                  } else {
                    symbol = '€';
                    currency = 'eur';
                  }
                  priceKey = `price_${days}_${currency}`;

                  basicPrice = currentPlan[priceKey];
              }
              if(currentPlan){
                return(
                  <>
                  <div style={{width:'95%',display:'flex',flexDirection:'row',fontSize:14,padding:10,borderBottom:'1px dashed #b8b8b8'}}>
                    <div style={{width:'70%',display:'flex',justifyContent:'flex-start',alignItems:'center'}}>
                      Renewal price of the plan <span style={{color:currentPlan.color_code,paddingRight:5,paddingLeft:5}}>{currentPlan.name}</span> for {days} days
                    </div>
                    <div style={{width:'30%',display:'flex',justifyContent:'flex-end',alignItems:'center',flexDirection:'row'}}>
                      {symbol} {parseFloat(basicPrice).toFixed(2)}
                    </div>
                  </div>
                  </>
                )
              }else{
                return(
                  <>
                  <CircleLoader />
                  </>
                )
              }
            }

            const CouponSlab = () =>{
              let symbol, currency, priceKey, basicPrice, discountPrice;
              if(selfProfile){
                  if (selfProfile.country === 'India') {
                    symbol = '₹';
                    currency = 'inr';
                  } else if (selfProfile.country === 'United States') {
                    symbol = '$';
                    currency = 'usd';
                  } else {
                    symbol = '€';
                    currency = 'eur';
                  }
                  priceKey = `price_${days}_${currency}`;

                  basicPrice = currentPlan[priceKey];
              }

              if(couponApplied && couponDiscount){
                discountPrice = parseFloat(basicPrice)*parseFloat(couponDiscount)/100;
              }

              return(
                <>
                <div style={{width:'95%',display:'flex',flexDirection:'row',fontSize:14,padding:10,borderBottom:'1px dashed #b8b8b8'}}>
                    <div style={{width:'50%',display:'flex',justifyContent:'flex-start',alignItems:'center'}}>
                      {couponDiscount}% Coupon discount
                    </div>
                    <div style={{width:'50%',display:'flex',justifyContent:'flex-end',alignItems:'center',flexDirection:'row'}}>
                      -{symbol} {parseFloat(discountPrice).toFixed(2)}
                    </div>
                  </div>
                </>
              )

            }

            const TaxSlabs = () =>{
              let symbol, currency, priceKey, basicPrice;
              if(selfProfile){
                  if (selfProfile.country === 'India') {
                    symbol = '₹';
                    currency = 'inr';
                  } else if (selfProfile.country === 'United States') {
                    symbol = '$';
                    currency = 'usd';
                  } else {
                    symbol = '€';
                    currency = 'eur';
                  }
                  priceKey = `price_${days}_${currency}`;

                  basicPrice = currentPlan[priceKey];
              }

              if(couponApplied && couponDiscount){
                const discountPrice = parseFloat(basicPrice)*parseFloat(couponDiscount)/100;
                basicPrice = parseFloat(basicPrice)-parseFloat(discountPrice);
              }

              if(selfProfile.state === 'Maharashtra'){
                const cgstPerc = selfProfile.essentials.cgst;
                const sgstPerc = selfProfile.essentials.sgst;
                const cgst = parseFloat(basicPrice)*parseFloat(cgstPerc)/100;
                const sgst = parseFloat(basicPrice)*parseFloat(sgstPerc)/100;
                return(
                  <>
                  <div style={{width:'95%',display:'flex',flexDirection:'row',fontSize:14,padding:10,borderBottom:'1px dashed #b8b8b8'}}>
                    <div style={{width:'50%',display:'flex',justifyContent:'flex-start',alignItems:'center'}}>
                      CGST @ {cgstPerc}% 
                    </div>
                    <div style={{width:'50%',display:'flex',justifyContent:'flex-end',alignItems:'center',flexDirection:'row'}}>
                      {symbol} {parseFloat(cgst).toFixed(2)}
                    </div>
                  </div>
                  <div style={{width:'95%',display:'flex',flexDirection:'row',fontSize:14,padding:10,borderBottom:'1px dashed #b8b8b8'}}>
                    <div style={{width:'50%',display:'flex',justifyContent:'flex-start',alignItems:'center'}}>
                      SGST @ {cgstPerc}% 
                    </div>
                    <div style={{width:'50%',display:'flex',justifyContent:'flex-end',alignItems:'center',flexDirection:'row'}}>
                      {symbol} {parseFloat(sgst).toFixed(2)}
                    </div>
                  </div>
                  </>
                )
              }else{
                const igstPerc = selfProfile.essentials.igst;
                const igst = parseFloat(basicPrice)*parseFloat(igstPerc)/100;
                return(
                  <>
                  <div style={{width:'95%',display:'flex',flexDirection:'row',fontSize:14,padding:10,borderBottom:'1px dashed #b8b8b8'}}>
                    <div style={{width:'50%',display:'flex',justifyContent:'flex-start',alignItems:'center'}}>
                      IGST @ {igstPerc}% 
                    </div>
                    <div style={{width:'50%',display:'flex',justifyContent:'flex-end',alignItems:'center',flexDirection:'row'}}>
                      {symbol} {parseFloat(igst).toFixed(2)}
                    </div>
                  </div>
                  </>
                )
              }
            }

            useEffect(()=>{
              if(selfProfile){
                calculateFinalPrice();
              }
            },[allPlans,currentPlan,couponDiscount,selfProfile]);

            const calculateFinalPrice = () =>{
              let symbol, currency, priceKey, basicPrice, finalPrice;
              if(selfProfile){
                  if (selfProfile.country === 'India') {
                    symbol = '₹';
                    currency = 'inr';
                  } else if (selfProfile.country === 'United States') {
                    symbol = '$';
                    currency = 'usd';
                  } else {
                    symbol = '€';
                    currency = 'eur';
                  }
                  priceKey = `price_${days}_${currency}`;

                  basicPrice = currentPlan[priceKey];
              }
              if(couponApplied && couponDiscount){
                const discountPrice = parseFloat(basicPrice)*parseFloat(couponDiscount)/100;
                basicPrice = parseFloat(basicPrice)-parseFloat(discountPrice);
              }

              if(selfProfile.state === 'Maharashtra'){
                const cgstPerc = selfProfile.essentials.cgst;
                const sgstPerc = selfProfile.essentials.sgst;
                const cgst = parseFloat(basicPrice)*parseFloat(cgstPerc)/100;
                const sgst = parseFloat(basicPrice)*parseFloat(sgstPerc)/100;
                finalPrice = parseFloat(basicPrice)+parseFloat(cgst)+parseFloat(sgst);

              }else{
                const igstPerc = selfProfile.essentials.igst;
                const igst = parseFloat(basicPrice)*parseFloat(igstPerc)/100;
                finalPrice = parseFloat(basicPrice)+parseFloat(igst);
              }

              setFinalMainPrice(finalPrice);
            }

            const FinalPrice = () =>{
              let symbol, currency;
              if(selfProfile){
                  if (selfProfile.country === 'India') {
                    symbol = '₹';
                    currency = 'inr';
                  } else if (selfProfile.country === 'United States') {
                    symbol = '$';
                    currency = 'usd';
                  } else {
                    symbol = '€';
                    currency = 'eur';
                  }
                  
              }
              if(finalMainPrice){

                return(
                  <div style={{width:'95%',display:'flex',flexDirection:'row',fontSize:14,padding:10,borderBottom:'1px dashed #b8b8b8'}}>
                    <div style={{width:'50%',display:'flex',justifyContent:'flex-start',alignItems:'center', fontSize:20}}>
                      Total
                    </div>
                    <div style={{width:'50%',display:'flex',justifyContent:'flex-end',alignItems:'center',flexDirection:'row',fontWeight:'bold'}}>
                      {symbol} {parseFloat(finalMainPrice).toFixed(2)}
                    </div>
                  </div>
                )

              }
            }

            const initiatePayment = async() =>{
              const amount = parseFloat(finalMainPrice).toFixed(2);
              const purpose = `${currentPlan.name} plan renewal for ${days} days`;
              const name = selfProfile.entity_name || 'no name';
              const email = selfProfile.email;
              const phone = selfProfile.phone;
              const user_id = localStorage.getItem('sp_unique');
              const plan_id = currentPlan.plan_id;
              const currency = selfProfile.country === 'India' ? 'INR' : selfProfile.country === 'United States' ? 'USD' : 'EUR';
              const coupon = couponCode || null;
              const addDiscount = null;
              setTaskLoader(true);
              try{
                const dataString = {
                  amount,
                  purpose,
                  name,
                  email,
                  phone,
                  user_id,
                  plan_id,
                  currency,
                  days,
                  coupon,
                  addDiscount
                }
      
                const response = await fetch('https://access.myservicecircle.app/payment_gateway/web/paymentRenewalMain.php', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(dataString),
                });
      
              const result = await response.json();
                console.log(result);
              if (result.success == 1) {
                  // Extract the payment URL and parameters
                  const { payment_url, params } = result;
      
                  // Create a form dynamically and submit it
                  const form = document.createElement('form');
                  form.method = 'POST';
                  form.action = payment_url;
      
                  Object.keys(params).forEach((key) => {
                      const input = document.createElement('input');
                      input.type = 'hidden';
                      input.name = key;
                      input.value = params[key];
                      form.appendChild(input);
                  });
      
                  document.body.appendChild(form);
                  form.submit();
              } else {
                  console.error('Error in initiating payment:', result.error);
                  alert(result.error || 'Failed to initiate payment. Please try again.');
              }
              }catch(error){
      
              }finally{
                setTaskLoader(false);
              }
              
            }

            const renewForFree = async () =>{

            }

    return(
        <>
        <div style={{ display: 'flex', height: '100vh'}}>
        <div style={{width:'50%',height:'70vh',backgroundColor:'#fff',display:'flex',flexDirection:'column',padding:10,borderRadius:10,boxShadow:'0px 10px 5px rgba(0, 0, 0, 0.2'}} className='billDetails'>
            {!couponApplied ?
                <>
                <div style={{display:'flex',flexDirection:'column',width:'100%'}}>
                    <div style={{width:'100%',display:'flex',justifyContent:'center',alignItems:'center',fontSize:12,color:'#000',textAlign:'center',flexDirection:'row',gap:10}}>
                                      
                    <div style={{width:'auto',justifyContent:'center',alignItems:'center',display:'flex'}}>Got a coupon code?</div>
                        <a style={{width:'auto',justifyContent:'center',alignItems:'center',display:'flex',cursor:'pointer'}} onClick={()=>setCouponBox(true)}>Click here to apply</a>  
                    </div>
                        
                    {couponBox &&
                      <div style={{width:'100%',display:'flex',justifyContent:'center',alignItems:'center',fontSize:12,color:'#000',textAlign:'center',flexDirection:'row',gap:10}}>
                        <TextInput name="text-input-name" placeholder="Type or pase your coupon code" style={{width:150,textAlign:'center'}} value={couponCode} onChange={handleCouponCode}/>
                        <span onClick={verifyCouponCode} style={{ cursor: 'pointer', color: '#000', display: 'flex', justifyContent: 'center', alignItems: 'center', }} >
                          <MdCheck style={{ color: '#000', fontSize: 20 }} />
                        </span>
                        <span onClick={() => {setCouponBox(false);setCouponCode(null)}} style={{ cursor: 'pointer', color: '#000', display: 'flex', justifyContent: 'center', alignItems: 'center', }} >
                          <MdClose style={{ color: '#000', fontSize: 20 }} />
                        </span>
                      </div>
                      }
                </div>
                </>
                    :
                <div style={{width:'100%',display:'flex',justifyContent:'center',alignItems:'center',fontSize:12,color:'#000',textAlign:'center',flexDirection:'row',gap:10}}>
                    <div style={{width:'auto',justifyContent:'center',alignItems:'center',display:'flex'}}>Coupon code applied</div>
                      <MdCheck style={{fontSize:20,color:'green'}} />
                    </div>
              }

                <div style={{marginTop:20}}>
                  <BasePriceSlab />
                  {couponApplied && couponDiscount &&
                    <CouponSlab />
                  }
                  {selfProfile && selfProfile.country === 'India' &&
                    <TaxSlabs />
                  }

                  {finalMainPrice &&
                    <FinalPrice />
                  }

                  <div style={{width:'100%',display:'flex',justifyContent:'center',alignItems:'center',padding:20}}>
                  {finalMainPrice && finalMainPrice>0 ?
                    <>
                      <Button style={{color:'#fff',backgroundColor:'#000'}} onClick={initiatePayment}>Pay now</Button>
                    </>
                    :
                    <>
                      <Button style={{color:'#fff',backgroundColor:'#000'}}onClick={renewForFree}>Renew for free</Button>
                    </>
                  }
                  </div>
                </div>
                </div>
              </div>
                      {taskLoader &&
                         
                          <div className='loaderBox'>
                            <CircleLoader color='#FCC100' size={30} />
                          </div>          
                         
                        }
        </>
    )
}

export default PaymentPlanRenew;