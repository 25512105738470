import React, {useState, useEffect, CSSProperties } from 'react';
import logoLong from '../../assets/images/logo_full_length.png';
import logoSmall from '../../assets/images/logo.png';
import upscaledImage from '../../assets/images/upscaled.png';
import BottomNavigation from '../Common/BottomNavigation';
import { Swiper, SwiperSlide } from 'swiper/react';
import './Success.css';
import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Autoplay } from 'swiper/modules';
import { motion } from "framer-motion";
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom'
import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import { 
  FaHome, FaUserAlt, FaCog, FaClipboard, FaChartBar, FaTools, FaRegFileAlt, 
  FaUsers, FaUserTie, FaComments, FaBell, FaShoppingCart, 
  FaFontAwesome
} from 'react-icons/fa';
import { AiOutlineArrowRight, AiOutlineArrowLeft } from "react-icons/ai";
import { FiPackage } from "react-icons/fi";
import HeaderBar from '../Common/HeaderBar';
import SideBar from '../Common/SideBar';

import '../Dashboard/Dashboard.css';

import { BounceLoader, CircleLoader, ClipLoader, BeatLoader, BarLoader, FadeLoader } from 'react-spinners';
import { EditablePropsProvider } from '@chakra-ui/react';
import { Pane, Heading, Text, Card, Button, Dialog, TextInput, AlignJustifyIcon} from 'evergreen-ui';
import { toaster } from 'evergreen-ui';
import { MdOutlineChat, MdCall, MdOutlineVideoCameraFront, MdOutlineCheck, MdOutlineCancel, MdOutlineAddShoppingCart, MdBorderRight, MdCheck, MdClose, MdCheckCircle} from "react-icons/md";
import { LuBicepsFlexed } from 'react-icons/lu';

const Success = () =>{

    const {txnId} = useParams();
    const navigate = useNavigate();
    useEffect(()=>{
        if(txnId){
            setTimeout(()=>{
                navigate('./Login');
            },3000);
        }
    },[txnId]);
    
    return(
        <>
        <div className='billDetails' style={{borderRadius:20,boxShadow:'0px 20px 10px rgba(0, 0, 0, 0.2)',display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
                <h2 style={{fontWeight:100}}>Payment successful!</h2>
                <MdCheckCircle style={{fontSize:60,color:'green'}} />
                <div style={{fontSize:12,color:'#000',fontWeight:300}}>Redirecting you to the dashboard...</div>
                
        </div>
        </>
    )

}

export default Success;