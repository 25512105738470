
import React, {useState, useEffect, useRef} from 'react';
import './Login.module.css';
import logo from '../../assets/images/logo_full_length.png';
import logoRound from '../../assets/images/logo.png';
import upscaledImage from '../../assets/images/upscaled.png';
import BottomNavigation from '../Common/BottomNavigation';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Autoplay } from 'swiper/modules';
import { motion } from "framer-motion";
import { useNavigate } from 'react-router-dom';
import QRCode from "react-qr-code";
import { BounceLoader, CircleLoader, ClipLoader, BeatLoader, BarLoader, FadeLoader } from 'react-spinners';

function Login() {
    const [showQRCode, setShowQRCode] = useState(true);
    const [isFlipped, setIsFlipped] = useState(false);
    const [flipped, setFlipped] = useState(false);
    const [taskLoader, setTaskLoader] = useState(false);
    const [token, setToken] = useState(null);
    const [loginError, setLoginError] = useState("");
    const [tokenSend, setTokenSend] = useState(false);

    const ws = useRef();
    const navigate = useNavigate();

    const toggleFlip = () => {
      setIsFlipped(!isFlipped);
    };

    const closeErrorDialog = () => {
      setLoginError("");
    };
  
    function generateToken(length = 150) {
      const charset = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      const array = new Uint8Array(length);
      window.crypto.getRandomValues(array);
      return Array.from(array, (byte) => charset[byte % charset.length]).join('');
    }
    
    const decodeSPUnique = (encoded) => atob(encoded);
    const setupWebSocket = () => {
      ws.current = new WebSocket('wss://ws.myservicecircle.app:8021');
    
      ws.current.onopen = () => {
        //console.log('WebSocket connection established');
        if (token && ws.current.readyState === WebSocket.OPEN) {
          ws.current.send(JSON.stringify({ messageType: "register", userId: token }));
          //console.log(`Registered token: ${token}`);
        }
      };
    
      ws.current.onmessage = (event) => {
        try {
          const data = JSON.parse(event.data);
          //console.log('Received message:', data);
          const decodedTo = decodeSPUnique(data.to);
          if(data.messageType === 'loginToken' && decodedTo === token){
            loginTheUser(data.to,data.from);
          }
        } catch (error) {
          console.error('Error parsing WebSocket message:', error);
        }
      };
    
      ws.current.onerror = (error) => {
        console.error('WebSocket error:', error);
      };
    
      ws.current.onclose = () => {
        //console.log('WebSocket connection closed. Retrying in 5 seconds...');
        setTimeout(setupWebSocket, 5000);
      };
    };
    
    useEffect(() => {
      setupWebSocket();
      return () => ws.current?.close();
    }, [token]);
    
    

    useEffect(() => {
      setToken(generateToken());
      const interval = setInterval(() => {
        if (ws.current.readyState === WebSocket.OPEN) {
          const newToken = generateToken();
          setToken(newToken);
        }
      }, 180000);
    
      return () => clearInterval(interval);
    }, []);
    
    const handleLogin = async (e) => {
      e.preventDefault();
      const username = document.querySelector('input[placeholder="Username"]').value;
      const password = document.querySelector('input[placeholder="Password"]').value;
      const token = generateToken();
      if (!username || !password) {
        setLoginError("Username and password are required.");
        return;
      }
    
      setTaskLoader(true);
    
      try {
        const response = await fetch('https://access.myservicecircle.app/wcapi/2_manualLogin.php', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ username, password, token }),
        });
    
        const data = await response.json();
        //console.log(data);
        if (data.success == 1) {
          localStorage.setItem('sp_unique', data.sp_unique);
          localStorage.setItem('token', data.token);
          navigate(`/Dashboard?token=${data.token}&spUnique=${data.sp_unique}`); // Pass token via URL
        } else {
          setLoginError('Invalid login credentials.');
        }
      } catch (error) {
        console.error("Login error:", error);
        setLoginError("An unexpected error occurred.");
      } finally {
        setTaskLoader(false);
      }
    };
    
    
    

    const loginTheUser = async (to, from)=>{
      const sp_unique = decodeSPUnique(from);
      const token = decodeSPUnique(to);
      setTokenSend(true);
      try{
        const dataString = {
          sp_unique,
          token
        }
        //console.log(dataString);

        const response = await fetch('https://access.myservicecircle.app/wcapi/5_webclientLoginScan.php',{
          method:'POST',
          headers:{
            'Content-Type' : 'application/json'
          },
          body: JSON.stringify(dataString)
        });

        const responseData = await response.json();
        //console.log(responseData);
        if(responseData.success == 1){
          localStorage.setItem('token', token);
          localStorage.setItem('sp_unique', sp_unique);
          sendLoginConfirmation(token,sp_unique);
        }else{
          setLoginError('Something went wrong... You should try refreshing the page and restarting the app once');
        }
      }catch(error){
        //console.log(error);
      }finally{
        setTokenSend(false);
      }
    }

    const encodeSPUnique = (sp_unique) => btoa(sp_unique);
    const sendLoginConfirmation = (from, to) =>{
      if(ws.current.readyState === WebSocket.OPEN){
        const msg = {
          messageType: 'loginToken',
          to: encodeSPUnique(to),
          from:encodeSPUnique(from),
          status:'connected'
        }

        ws.current.send(JSON.stringify(msg));
        navigate('./Dashboard');
      }
    }

  return (
    <>
    {loginError && (
        <div style={{position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', backgroundColor: '#fff', border: '1px solid #ccc', borderRadius: '10px', padding: '20px', boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)', textAlign: 'center', zIndex: 1000, }} >
          <p style={{ color: '#993366', marginBottom: '20px' }}>{loginError}</p>
          <button  onClick={closeErrorDialog} style={{ padding: '10px 20px', backgroundColor: '#993366', color: '#fff', border: 'none', borderRadius: '8px', cursor: 'pointer', }} >
            Close
          </button>
        </div>
      )}

      <div style={{ width: '100vh', padding: 10, justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
        <img src={logo} alt="Logo" style={{ width: '55vh' }} />
      </div>

      
      <div style={{ width: '100%', justifyContent: 'center', alignItems: 'center', display: 'flex', marginTop: '20px',paddingBottom:150 }} >
        <div style={{ width: '80%', minHeight: '60%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', backgroundColor: '#fff', borderRadius: 15, boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)', }} >
          
        <div style={{width: '50%',verflow: 'hidden',justifyContent: 'center',alignItems: 'center',paddingBottom: -10,position: 'relative',display: 'flex',flexDirection: 'column',}}>
           
            <img src={upscaledImage} alt="Image" style={{ width: '80%', borderTopLeftRadius: 10, objectFit: 'cover',  }} />

            <div style={{ width: '80%', height: '200px', position: 'absolute', top: '10%', left: '50%', transform: 'translateX(-50%)', zIndex: 10, color: '#fff', justifyContent: 'center', alignItems: 'center', borderRadius: 10, display: 'flex', overflow: 'hidden', boxShadow: '0px 30px 40px rgba(0, 0, 0, 0.2)', }} >
            <Swiper modules={[Autoplay]} autoplay={{ delay: 3000, disableOnInteraction: false }} loop={true} spaceBetween={0} slidesPerView={1} style={{ width: '100%', height: '100%', boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',  }} >
                
                <SwiperSlide>
                <div style={{ backgroundColor: '#554694', width: '100%', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)', }} >
                    <p style={{ fontSize: '18px', fontWeight: 'bold' }}>Why This Matters?</p>
                    <p style={{ fontSize: '14px', marginTop: '5px' }}>
                    Improve operational efficiency, boost customer satisfaction, and increase revenue by analyzing trends and optimizing resources.
                    </p>
                </div>
                </SwiperSlide>

                
                <SwiperSlide>
                <div style={{ backgroundColor: '#306DB3', width: '100%', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center',
                    alignItems: 'center', textAlign: 'center', boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)', }} >
                    <p style={{ fontSize: '18px', fontWeight: 'bold' }}>Service Requests Reports</p>
                    <p style={{ fontSize: '14px', marginTop: '5px' }}>
                    Gain insights on total service requests, resolution times, and categorize data by products or services.
                    </p>
                </div>
                </SwiperSlide>

                
                <SwiperSlide>
                <div style={{ backgroundColor: '#783086', width: '100%', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)', }} >
                    <p style={{ fontSize: '18px', fontWeight: 'bold' }}>Complaint Reports</p>
                    <p style={{ fontSize: '14px', marginTop: '5px' }}>
                    Track total complaints, measure resolution success rates, and identify recurring issues for better service insights.
                    </p>
                </div>
                </SwiperSlide>

                
                <SwiperSlide>
                <div style={{ backgroundColor: '#FCC100', width: '100%', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)', }} >
                    <p style={{ fontSize: '18px', fontWeight: 'bold' }}>Subscription Reports</p>
                    <p style={{ fontSize: '14px', marginTop: '5px' }}>
                    View active subscriptions, renewal dates, and revenue generated from various plans and add-ons.
                    </p>
                </div>
                </SwiperSlide>

                
                <SwiperSlide>
                <div style={{ backgroundColor: '#CE6081', width: '100%', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)', }} >
                    <p style={{ fontSize: '18px', fontWeight: 'bold' }}>Revenue Reports</p>
                    <p style={{ fontSize: '14px', marginTop: '5px' }}>
                    Track revenue trends by month or year, break down by subscription types, and identify high-value clients.
                    </p>
                </div>
                </SwiperSlide>

                
                <SwiperSlide>
                <div style={{ backgroundColor: '#554694', width: '100%', height: '100%', display: 'flex', flexDirection: 'column',
                    justifyContent: 'center', alignItems: 'center', textAlign: 'center', boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)', }} >
                    <p style={{ fontSize: '18px', fontWeight: 'bold' }}>Client Interaction Reports</p>
                    <p style={{ fontSize: '14px', marginTop: '5px' }}>
                    Identify active vs. inactive clients, analyze feedback trends, and highlight high-value clients for better engagement.
                    </p>
                </div>
                </SwiperSlide>

                
                <SwiperSlide>
                <div style={{ backgroundColor: '#306DB3', width: '100%', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center',
                    alignItems: 'center', textAlign: 'center', boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)', }} >
                    <p style={{ fontSize: '18px', fontWeight: 'bold' }}>Product/Service Reports</p>
                    <p style={{ fontSize: '14px', marginTop: '5px' }}>
                    Discover the most serviced products, analyze trends, and improve quality for products with frequent complaints.
                    </p>
                </div>
                </SwiperSlide>

                
                <SwiperSlide>
                <div  style={{ backgroundColor: '#783086', width: '100%', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)', }} >
                    <p style={{ fontSize: '18px', fontWeight: 'bold' }}>Agent Performance Reports</p>
                    <p style={{ fontSize: '14px', marginTop: '5px' }}>
                      Track agent tasks, analyze ratings, and optimize workload by identifying the most and least utilized agents.
                    </p>
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>


            </div>

          
          <div style={{ width: '50%', padding: '10px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent:'center'}} >
                <div  style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "50vh",width:'100%' }} >
                  <motion.div style={{ width: "100%", height: "50vh", perspective: "1000px", display: "flex", justifyContent: "center", alignItems: "center",  textAlign: "center", }} >

                    <motion.div initial={{ rotateY: 0 }} animate={{ rotateY: isFlipped ? 180 : 0 }} transition={{ duration: 0.3 }} style={{ width: "80%", height: "80vh", position: "relative",transformStyle: "preserve-3d",borderRadius: "10px",boxShadow: "0px 0px 15px rgba(0, 0, 0, 0.2)",backgroundColor: "#fff",display: "flex",justifyContent: "center", alignItems: "center",}}>    

                      <motion.div style={{position: "absolute",width: "80%", height: "80vh", backfaceVisibility: "hidden",  borderRadius: "10px",display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", padding: "20px", textAlign: "center", }} >
                        <div style={{ width: "200px", height: "200px", backgroundColor: "#f2f2f2", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",flexDirection:'column' }} >
                          <span style={{ color: "#aaa" }}>
                          {tokenSend ?
                            <>
                            <CircleLoader size={20} color='#FCC100' />
                            </>
                            :
                            <>
                            {token ?
                            <div style={{ position: "relative", display: "inline-block" }}>
                            <QRCode value={token} size={200} />
                           
                            <img
                              src={logoRound}
                              alt="Logo"
                              style={{
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                transform: "translate(-50%, -50%)",
                                width: "50px", 
                                height: "50px",
                                borderRadius: "50%", 
                              }}
                            />
                          </div>
                            :
                            <CircleLoader size={20} color='#FCC100' />

                          }
                            </>
                          }
                          

                          </span>                          
                        </div>
                        <ul style={{ padding: 0, margin: 0, listStyle: "none", display: "flex", flexDirection: "column", gap: "8px", fontSize: "12px", color: "#333",marginTop:20 }}>
                            <li style={{ display: "flex", alignItems: "center", gap: "8px", backgroundColor: "#F3F4F6", padding: "10px", borderRadius: "6px", boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)",textAlign:'left' }}><span style={{width: "20px",height: "20px",backgroundColor: "#554694",color: "#fff",borderRadius: "50%",display: "flex",justifyContent: "center",alignItems: "center",fontSize: "12px",fontWeight: "bold",}}>1</span>Open the app</li>
                            <li style={{display: "flex",alignItems: "center",gap: "8px",backgroundColor: "#F3F4F6",padding: "10px",borderRadius: "6px",boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)",textAlign:'left'}}><span style={{width: "20px",height: "20px",backgroundColor: "#554694",color: "#fff",borderRadius: "50%",display: "flex",justifyContent: "center",alignItems: "center",fontSize: "12px",fontWeight: "bold",}}>2</span>Press 'Web client access' in the 'Do more with MyServiceCircle.App' box</li>  
                            <li style={{display: "flex",alignItems: "center",gap: "8px",backgroundColor: "#F3F4F6",padding: "10px",borderRadius: "6px",boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)",textAlign:'left'}}><span style={{width: "20px",height: "20px",backgroundColor: "#554694",color: "#fff",borderRadius: "50%",display: "flex",justifyContent: "center",alignItems: "center",fontSize: "12px",fontWeight: "bold",}}>3</span>Scan the QR code here using the scanner that opens</li>
                          </ul>
                        <p onClick={toggleFlip} style={{ marginTop: "20px", fontSize: "12px", color: "#993366", textDecoration: "underline", cursor: "pointer", }} >
                          Click here to sign in with username and password
                        </p>
                      </motion.div>
                      
                      <motion.div style={{ position: "absolute", width: "80%", height: "50vh", backfaceVisibility: "hidden", borderRadius: "10px",
                          display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", padding: "20px", transform: "rotateY(180deg)",}}>
                        <form
                          style={{width: "100%",display: "flex",flexDirection: "column",justifyContent: "center",alignItems: "center",textAlign: "center",}} onSubmit={handleLogin}>
                          <input type="text" placeholder="Username" style={{ width: "100%", padding: "10px", margin: "10px 0", border: "1px solid #ccc", borderRadius: "8px", fontSize: "16px", }} />
                          <input type="password" placeholder="Password" style={{ width: "100%",padding: "10px",margin: "10px 0",border: "1px solid #ccc",borderRadius: "8px",fontSize: "16px",}}/>
                          <button type="submit" style={{ width: "100%", padding: "10px", backgroundColor: "#993366", color: "#fff", border: "none", borderRadius: "8px", fontSize: "16px", cursor: "pointer", marginTop: "10px", }} >
                            Login
                          </button>
                        </form>
                        <p onClick={toggleFlip} style={{ marginTop: "20px", fontSize: "14px", color: "#993366", textDecoration: "underline", cursor: "pointer", }} >
                          Click here to sign in using QR code
                        </p>
                      </motion.div>

                    </motion.div>
                    
                  </motion.div>
                </div>
          </div>

        </div>
      <BottomNavigation />
      
      </div>
      
    </>
  );
}

export default Login;